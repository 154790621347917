import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Navbar,
  Nav,
  Button,
  Offcanvas,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { useNavigate, useLocation, useHref, useResolvedPath } from "react-router-dom";
import { useAuth } from "../auth";
import { SignInDrawer } from "./SignInDrawer";
import { AppContext } from "../context";
import SearchIcon from "@mui/icons-material/Search";
import "./Header.css";
import LoggedInNavbar from "../components/dashboard/Components/Dashboard/Navbar";
import { validate_token } from "./core/_request";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Cookies from "js-cookie";
import { Avatar } from "@mui/material";
import { useGlobalStore } from "../store/useGlobalStore";

const searchPlaceholder = ["an Expert", "a Program", "a Blog"];

function Header2({ cssClass }) {
  const { currentUser, userType, login, logout, setCurrentUser } = useAuth();
  const { isAuthenticated, user, userLogin, userLogout } = useGlobalStore();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const [changingPlaceholder, setChangingPlaceholder] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [b2BImage, setB2BImage] = useState();
  const [loading, setLoading] = useState(false);
  const [hasRedirected, setHasRedirected] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const token_url = new URLSearchParams(search).get("token");
  const cookie_token = Cookies.get("authToken");
  const authUserType = Cookies.get("authUserRole");
  const searchParams = new URLSearchParams(location.search);

  const [previousLocation, setPreviousLocation] = useState(null);

  const stellaAPIKey = process.env.REACT_APP_STELLAAPIKEY;
  const baseURL = "https://odoo.unitedwecare.ca";
  let currentLocation = window.location.pathname

  useEffect(() => {
    const decodeAndReplaceURL = (url) => {
      const decodedUrl = decodeURIComponent(url);
      const [baseUrl, queryParams] = decodedUrl?.split('?');
      // const updatedQueryParams = queryParams.replace('?', '&');
      // const updatedUrl = `${baseUrl}?${updatedQueryParams}`;
      // window.location.replace(`${updatedUrl}`); 
      navigate(`?${queryParams}`)
    };

    const originalUrl = window.location.href;
    decodeAndReplaceURL(originalUrl);
  }, []);


  useEffect(() => {
    const profileData = localStorage.getItem("profile");
    const parsedProfile = profileData ? JSON.parse(profileData) : null;
    const headerImage = parsedProfile?.header_image;
    if (headerImage) {
      setB2BImage(headerImage);
    }
  }, []);

  const handleLogin = () => {
    const currentUrl = encodeURIComponent(window.location.href);
    window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
  }

  // const checkAuthAndFetchData = useCallback(async () => {
  //   if (cookie_token && !currentUser) {
  //     try {
  //       const resp = await validate_token(cookie_token);
  //       if (resp.ResponseCode === 200) {
  //         localStorage.setItem("Authorization", resp?.data?.usertoken);
  //         localStorage.setItem(
  //           "profile",
  //           JSON.stringify(resp?.data?.userdetails)
  //         );
  //         login(resp.data.userdetails);
  //       } else {
  //         console.log(resp?.Response);
  //       }
  //     } catch (error) {
  //       console.error("Error in validateToken:", error);
  //     }
  //   } else if (currentUser) {
  //   }
  // }, [cookie_token, currentUser, login]);

  // useEffect(() => {
  //   if (cookie_token) {
  //     checkAuthAndFetchData();
  //   }
  // }, [cookie_token, checkAuthAndFetchData]);

  const redirectUser = (currentUrl, authUserType) => {
    // Define the redirection mapping based on the user type

    const userTypeArray = authUserType?.split(',').map(type => type.trim());
    const primaryUserType = userTypeArray[0];

    const redirectionMapping = {
      user: {
        login: '/user-dashboard',
        logout: 'https://unitedwecare.com/',
        default: (url) => url
      },
      expert: {
        login: '/expert/dashboard',
        logout: 'https://unitedwecare.com/',
        default: '/expert/dashboard'
      },
      copilot: {
        login: 'https://copilot.unitedwecare.com/auth',
        logout: 'https://www.unitedwecare.com/clinical-co-pilot/',
        default: 'https://copilot.unitedwecare.com/auth'
      }
    };

    // Get the appropriate redirect URL based on user type
    const getRedirectUrl = () => {
      const urlParts = currentUrl?.split('/');
      const baseUrl = urlParts[0] + '//' + urlParts[2];
      const pathname = urlParts[3] || '';
      console.log("pathname", urlParts);
      if (currentUrl.includes('/logout')) {
        return redirectionMapping[primaryUserType].logout;
      } else if (urlParts[2] === 'accounts.unitedwecare.com') {
        return redirectionMapping[primaryUserType].login;
      } else if (urlParts[2] === 'my.unitedwecare.com' && urlParts[3] === 'profession') {
        return redirectionMapping[primaryUserType].default;
      } else if (urlParts[2] === 'stella.unitedwecare.com' || urlParts[2] === 'programs.unitedwecare.com') {
        return redirectionMapping[primaryUserType].default;
      } else if (urlParts[2] === 'stella.unitedwecare.com' && urlParts[3].includes('payload')) {
        return redirectionMapping[primaryUserType].default;
      } else if (currentUrl === '/') { // Check if the path starts with '?Profession'
        return redirectionMapping[primaryUserType]?.login; // Redirect to login/dashboard page
      } else {
        return redirectionMapping[primaryUserType].default;
      }
    };

    // Redirect to the computed URL 
    if (primaryUserType === "copilot") {
      window.location.href = getRedirectUrl();
    } else {
      navigate(getRedirectUrl());
    }
  };

  const validateTokenFunction = () => {
    if (cookie_token && !currentUser) {
      validate_token(cookie_token).then((resp) => {
        if (resp?.ResponseCode === 200) {
          login(resp?.data);
          setCurrentUser(resp?.data?.userdetails);
          userLogin(resp?.data?.userdetails);
          // if (currentLocation === "/" && authUserType === "user") {
          //   navigate("/user-dashboard");
          // }
          // // else if (currentLocation === "/" && authUserType == "expert") {
          // //   navigate('/expert/dashboard')
          // // } else if (currentLocation === "/" && authUserType == "copilot expert") {
          // //   navigate('/expert/dashboard')
          // // } else if (currentLocation === "/" && authUserType == "copilot expert") {
          // //   navigate('/expert/dashboard')
          // // }

          // else {
          //   searchParams.delete("token");
          //   const searchString = searchParams.toString();
          //   const paramsArray = searchString.split("&");
          //   const filteredParamsArray = paramsArray.filter(
          //     (param) => !param.startsWith("token=")
          //   );
          //   const queryString = filteredParamsArray
          //     .join("&")
          //     .replace(/\+/g, "%20");
          //   const updatedURL = `${location.pathname}?${queryString}${location.hash}`;

          //   window.location.href = updatedURL;
          // }

          let now = new Date();
          let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
          let firstName = resp?.data?.userdetails?.Name?.split(" ")
            .slice(0, 1)
            .join("");
          document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
          document.cookie = "userType=expert/user;";
        }
      }).catch((error) => {
        console.error("Error in validate_token with token_url:", error);
      });
    }
  }

  useEffect(() => {
    if (cookie_token && !isAuthenticated) {
      validateTokenFunction()?.then(() => {
        if (isAuthenticated && !hasRedirected) {
          const currentUrl = window.location.pathname;
          redirectUser(currentUrl, authUserType);
          setHasRedirected(true);
        }
      });
    }
  }, [cookie_token, isAuthenticated, hasRedirected]);

  useEffect(() => {
    console.log("isAuthenticated:", isAuthenticated);
    console.log("hasRedirected:", hasRedirected);
  
    if (isAuthenticated && !hasRedirected) {
      console.log("Redirecting user...");
      const currentUrl = window.location.pathname;
      redirectUser(currentUrl, authUserType);
      setHasRedirected(true);
    }
  }, [isAuthenticated, hasRedirected]);

  const onHeaderSearchIcon = () => {
    navigate(`/search/?query=${searchText}`);
  };

  const handleEnterKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onHeaderSearchIcon();
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setChangingPlaceholder((prevIndex) => (prevIndex === searchPlaceholder.length - 1 ? 0 : prevIndex + 1));
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const togglePopup = (state) => {
    setMenuOpen(false);

    const currentUrl = encodeURIComponent(window.location.href);
    window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => setMenuOpen(false);

  useEffect(() => {
    if (
      previousLocation &&
      previousLocation.pathname === "/onboarding-stella" &&
      location.pathname !== "/onboarding-stella"
    ) {

      const headers = {
        "Content-Type": "application/json",
        "X-API-Key": stellaAPIKey,
      };

      const sessionID = sessionStorage.getItem("stella_s_id");

      const body = {
        senderId: sessionID,
      };

      const getStellaClose = async () => {
        try {
          const response = await fetch(
            `${baseURL}/new-uwc/stella/close/session`,
            {
              method: "POST",
              headers,
              body: JSON.stringify(body),
            }
          );

          if (!response.ok) {
            throw new Error("Error fetching languages");
          }

          const jsonData = await response.json();
          console.log("RESPONSE", response);

          if (response.status === 200) {
            console.log("CLEAR");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      getStellaClose();
    }
    setPreviousLocation(location);
  }, [location, previousLocation, stellaAPIKey, baseURL]);

  const onLogout = () => {
    logout();
    userLogout();
    setHasRedirected(false);
    Cookies.remove("authUserRole", {
      domain: ".unitedwecare.com",
      path: "/",
      // secure: true,
      HttpsOnly: true,
    });
    Cookies.remove("authToken", {
      domain: ".unitedwecare.com",
      path: "/",
      // secure: true,
      HttpsOnly: true,
    });
    Cookies.remove("authToken");
    window.location.href = "https://www.unitedwecare.com/";
  };

  return (
    <>
      {(!currentUser && (
        <div
          style={{
            background: "#FFFFFF",
            boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.09)",
          }}
          className={`header-container kareify-Menu-header ${cssClass}`}
        >
          <Navbar key={"sm"} expand={"sm"} className={`col-12 Header--2 p-0`}>
            <div
              className="d-flex align-items-center justify-content-between col-12 my-2 my-md-0"
              style={{ padding: "0px 5%" }}
            >
              {/* logo Desktop */}
              <Navbar.Brand className="d-none d-md-flex gap-4 align-items-center kareify-logo-container p-0">
                <a
                  href="https://www.unitedwecare.com"
                  className="text-decoration-none"
                >
                  <img
                    alt=""
                    src="/assets/img/dashboard/UWC logo.png"
                    width={150}
                    className="d-block d-sm-none img-fluid"
                    style={{ objectFit: "contain" }}
                  />
                  <img
                    alt=""
                    src="/assets/img/dashboard/UWC logo.png"
                    width="326"
                    height="50"
                    fill="currentColor"
                    className="bi bi-search me-3 d-none d-md-block"
                    viewBox="0 0 16 16"
                    style={{ objectFit: "contain" }}
                  />
                </a>
              </Navbar.Brand>
              {/* logo Mobile */}
              <Navbar.Brand className="d-block d-md-none">
                <a
                  href="https://www.unitedwecare.com"
                  className="text-decoration-none"
                >
                  <img
                    src="/assets/img/kareify/UWC logo.png"
                    alt=""
                    width={300}
                    style={{ objectFit: "contain", padding: '10px' }}
                  />
                </a>
              </Navbar.Brand>

              {b2BImage && (
                <img
                  src={b2BImage}
                  alt=""
                  width={110}
                  height={65}
                  style={{ objectFit: "contain" }}
                />
              )}

              {/* Angel One Logo */}
              {b2BImage && (
                <Navbar.Brand className="d-block d-md-none">
                  <a href="" className="text-decoration-none">
                    <img
                      src={b2BImage}
                      alt=""
                      style={{ objectFit: "contain" }}
                    />
                  </a>
                </Navbar.Brand>
              )}
              <>
                <Navbar.Toggle
                  aria-controls={"offcanvasNavbar-expand-sm"}
                  onClick={toggleMenu}
                />
                <Navbar.Offcanvas
                  id={"offcanvasNavbar-expand-sm"}
                  aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                  placement="top"
                  className="header-offcanvas"
                  restoreFocus={false}
                  show={menuOpen}
                  onHide={handleClose}
                >
                  <Offcanvas.Header closeButton>
                    <Navbar.Brand className="d-block d-md-none">
                      <a
                        href="https://www.unitedwecare.com"
                        className="text-decoration-none"
                      >
                        <img
                          src="/assets/img/kareify/UWC logo.png"
                          alt=""
                          width={300}
                          style={{ objectFit: "contain", padding: '0px' }}
                        />
                      </a>
                    </Navbar.Brand>
                  </Offcanvas.Header>

                  <Nav className="header2--offcanvas-body offcanvas-body align-items-center align-items-md-center justify-content-center px-4">
                    {/* Mobile View Start */}
                    <Paper
                      component="form"
                      className="d-flex d-md-none"
                      sx={{ p: '2px 4px', alignItems: 'center', width: 300, border: '0.5px solid #cccccc', borderRadius: '10px', boxShadow: 'none !important' }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={`Search ${searchPlaceholder[changingPlaceholder]}`}
                        inputProps={{ 'aria-label': 'search google maps' }}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={handleEnterKeyDown}
                      />
                      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => navigate(`/search/?query=${searchText}`)}>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/enterprise/"
                    >Enterprise
                    </Nav.Link>
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/insurance/"
                    >
                      Insurance
                    </Nav.Link>
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/alliance/"
                    >
                      Alliance Partners
                    </Nav.Link>
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/about-us/"
                    >
                      About Us
                    </Nav.Link>
                    <Nav.Link
                      className="mx-1 d-block d-md-none text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/blog/"
                    >
                      Blog
                    </Nav.Link>

                    <div style={{ padding: "15px", width: "100%" }}>
                      <Button
                        onClick={handleLogin}
                        id="header_login"
                        className="text-white border-0 rounded-3 font-inter d-block d-md-none"
                        style={{
                          backgroundColor: "#73589B",
                          height: "45px",
                          width: '100%',
                          fontSize: "18px",
                          fontWeight: 500,
                          padding: '10p 20px'
                        }}
                      >
                        Login
                      </Button>
                    </div>
                    {/* Mobile View End */}
                    {/* Desktop View Start */}
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/enterprise/"
                    >Enterprise
                    </Nav.Link>
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/insurance/"
                    >
                      Insurance
                    </Nav.Link>
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/alliance/"
                    >
                      Alliance Partners
                    </Nav.Link>
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/about-us/"
                    >
                      About Us
                    </Nav.Link>
                    <Nav.Link
                      className="kareify-Menu-header-text mx-1 d-none d-md-block text-decoration-none lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                      href="https://www.unitedwecare.com/blog/"
                    >
                      Blog
                    </Nav.Link>
                  </Nav>
                  <Offcanvas
                    show={ShowLloginPopup}
                    onHide={() => togglePopup(false)}
                    placement="end"
                    className="m-0 signInModal"
                  >
                    <SignInDrawer />
                  </Offcanvas>
                </Navbar.Offcanvas>
              </>
              <Paper
                component="form"
                className="d-none d-md-flex"
                sx={{ p: '2px 4px', alignItems: 'center', width: 300, border: '0.5px solid #cccccc', borderRadius: '10px', boxShadow: 'none !important' }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder={`Search ${searchPlaceholder[changingPlaceholder]}`}
                  inputProps={{ 'aria-label': 'search google maps' }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={handleEnterKeyDown}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => navigate(`/search/?query=${searchText}`)}>
                  <SearchIcon />
                </IconButton>
              </Paper>
              <div className="align-items-center gap-3 d-none d-md-flex">
                <div style={{ padding: "15px" }}>
                  {(!currentUser && cookie_token) ?
                    <Avatar />
                    :
                    <Button
                      onClick={handleLogin}
                      id="header_login"
                      className="text-white border-0 rounded-3 px-4 font-inter"
                      style={{
                        backgroundColor: "#73589B",
                        height: "45px",
                        fontSize: "18px",
                        fontWeight: 500,
                      }}
                    >
                      Login
                    </Button>
                  }
                </div>
              </div>
            </div>
            <div className="d-flex d-md-none flex-column align-items-center justify-content-center col-12">
              <Form
                className="d-none d-md-none align-items-center justify-content-center rounded-2 col-11 my-2"
                style={{ border: "0.5px solid #541CF5", height: "45px" }}
              >
                <InputGroup>
                  <FormControl
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={handleEnterKeyDown}
                    type="search"
                    className="ms-2 border-0 headerSearch"
                    style={{ borderRight: "none" }}
                    placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`}
                  />
                  <SearchIcon
                    sx={{
                      color: "#541CF5",
                      display: "block",
                      marginY: "auto",
                      marginRight: "10px",
                    }}
                    onClick={onHeaderSearchIcon}
                  />
                </InputGroup>
              </Form>
            </div>
          </Navbar>
        </div>
      )) || <LoggedInNavbar cssClass={cssClass} onLogout={onLogout} />}
    </>
  );
}

export default Header2;
